import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class QRCodeScannerPage extends Component {
  constructor(props) {
    super(props);
    this.youtubeContainer = React.createRef();
  }

  render() {
    const { locale, t } = this.props;
    const sliderImages = [
      "qr_code_slider_phone1.png",
      "qr_code_slider_phone2.png",
      "qr_code_slider_phone3.png",
      "qr_code_slider_phone4.png",
    ];
    const sliderImageAlts = ["Screenshot of Personal Folder"];
    const sliderImageIds = ["img1PersonalFolderSlider"];
    const phoneSlider = (
      <CommonPhoneSlider
        backgoundImg={"qrcode-scanner-bg.png"}
        sliderID="PersonalFolderSlider"
        sliderTitle={t("QR Code / Licence Scanner")}
        sliderTitleID={"textPersonalFolderSliderTitle"}
        sliderText={t(" ")}
        sliderTextID="textPersonalFolderSliderContent"
        sliderImages={sliderImages}
        sliderImageAlts={sliderImageAlts}
        sliderImageIds={sliderImageIds}
        autoPlay={5}
        {...this.props}
      />
    );
    return (
      <div
        className={`page-container page-container-${locale} `}
        id="MainContent"
      >
        {phoneSlider}

        <div className="container-fluid second-feature-section">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textChildGrowthSection1Title"
                  className="second-feature-title"
                >
                  {t("Scan/Show eHealth QR code")}
                </div>
                <div
                  id="textChildGrowthSection1Content"
                  className="second-feature-text"
                >
                  {t(
                    "Scan/show the relevant eHealth QR codes to perform various functions in the eHealth App"
                  )}
                </div>
              </div>

              <div data-aos="" className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1ChildGrowthSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(`./resources/images/${locale}/qr_scanner_p1_1.png`)}
                  alt=" "
                />
                <img
                  id="img2ChildGrowthSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="0"
                  className="absolute-image middle"
                  src={load(`./resources/images/qr_scanner_p1_2.png`)}
                  alt=" "
                />
                <img
                  id="img2ChildGrowthSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(`./resources/images/qr_scanner_p1_3.png`)}
                  alt=" "
                />
              </div>
            </div>

            <div className="row ehealth-row-3col scanner-features-row">
              <div className="ehealth-3col-item col-lg-4 col-sm-12 ">
                <div className="ehealth-3col-item-content">
                  <img
                    className=""
                    src={load(`./resources/images/qr-scanner_function-1.png`)}
                  />
                  <h4>{t("Add my family members")}</h4>
                  <div className="ehealth-row-3col-content">
                    {t(
                      "Manage my family members’ health records by scanning their QR codes"
                    )}
                  </div>
                </div>
              </div>

              <div className="ehealth-3col-item col-lg-4 col-sm-12 ">
                <div className="ehealth-3col-item-content">
                  <img
                    className=""
                    src={load(`./resources/images/qr-scanner_function-2.png`)}
                  />
                  <h4>{t("Give sharing consent")}</h4>
                  <div className="ehealth-row-3col-content">
                    {t(
                      "Allow healthcare providers to access and deposit your health records by scanning their QR codes"
                    )}
                  </div>
                </div>
              </div>

              <div className="ehealth-3col-item col-lg-4 col-sm-12 ">
                <div className="ehealth-3col-item-content">
                  <img
                    className=""
                    src={load(`./resources/images/qr-scanner_function-3.png`)}
                  />
                  <h4>{t("Take e-attendance")}</h4>
                  <div className="ehealth-row-3col-content">
                    {t(
                      "Show your QR code for taking attendance and verifying identify"
                    )}
                    <span className="remarks-text">
                      {t("e attendance remarks text")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section ">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textChildGrowthSection2Title"
                  className="second-feature-title"
                >
                  {t("Licence Scanner")}
                </div>
                <div
                  id="textChildGrowthSection2Content"
                  className="second-feature-text"
                >
                  {t("Licence Scanner intro content")}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1ChildGrowthSection2"
                  data-aos="fade-up"
                  className="absolute-image "
                  src={load(`./resources/images/qr-scanner_p2_1.png`)}
                  alt=" "
                />
                <img
                  id="img2ChildGrowthSection2"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  className="absolute-image  back"
                  src={load(`./resources/images/qr-scanner_p2_2.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(QRCodeScannerPage);
